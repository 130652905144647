<template>
	<div id="app">
		<loading v-if="loading" />
		<div class="page-wrapper">
			<header-component />
			<navigation-component />
			<social />
			<router-view />
			<footer-component />
			<lightbox />
			<subscribe-popup />
		</div>
		<artist-loader />
	</div>
</template>

<script>
import { HTTP } from '@/app/api'
import HeaderComponent from '@/components/Header'
import FooterComponent from '@/components/Footer'
import NavigationComponent from '@/components/Navigation'
import Social from '@/components/Social'
import Loading from '@/components/Loading'
import Lightbox from '@/components/Lightbox'
import SubscribePopup from '@/components/Subscribe/SubscribePopup'
import ArtistLoader from '@/views/Artists/ArtistLoader'
import { mapState } from 'vuex'
import { loadOneTrust } from './utils/oneTrust'

export default {
	name: 'App',
	components: {
		HeaderComponent,
		FooterComponent,
		NavigationComponent,
		Social,
		Loading,
		Lightbox,
		SubscribePopup,
		ArtistLoader,
	},
	metaInfo: {
		titleTemplate: '%s / ' + process.env.VUE_APP_OG_TITLE,
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'initial-scale=1, width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:site_name', content: process.env.VUE_APP_OG_TITLE },
		],
		link: [
			{ rel: 'apple-touch-icon', size: '180x180', href: '/images/favicons/apple-touch-icon.png' },
			{ rel: 'icon', size: '32x32', type: 'image/png', href: '/images/favicons/favicon-32x32.png' },
			{ rel: 'icon', size: '16x16', type: 'image/png', href: '/images/favicons/favicon-16x16.png' },
			{ rel: 'manifest', href: '/images/favicons/site.webmanifest.json', crossorigin: 'use-credentials' },
			{ rel: 'preconnect', href: 'https://fonts.gstatic.com' },
		]
	},
	computed: {
        ...mapState(['theme', 'loading', 'artistLoader'])
	},
	created () {
		this.loadGeneral()
	},
	mounted () {
		
		loadOneTrust(window)
		this.setTheme()

	},
	watch: {
		$route() {
			this.resetStyles()
		}
	},
	methods: {
		setTheme () {
			var newTheme = 'light'
			if (localStorage.getItem('THEME')) {
				newTheme = localStorage.getItem('THEME')
			} else {
				newTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
			}
			this.$store.dispatch('updateTheme', newTheme)
		},
		loadGeneral () {
			HTTP({
				method: 'GET',
				url: '/general',
				crossdomain: true
			})
			.then(response => {
				this.$store.dispatch('updateGeneral', response.data)
			})
		},
		resetStyles () {
			document.body.classList.remove('alternate')
			document.body.classList.remove('subscribe-bg')
			document.body.style.backgroundColor = ''
		}
	}
}
</script>
